import { FormTemplateChildConfigs } from '@shared/components/json-defined-form/stateManagement/form';
import { IntegrationConfig } from '@shared/types/order';
import { FormValue } from 'clerk_common';
import {
  DEFAULT_REQUIRED_FIELDS,
  RequiredField,
} from 'clerk_common/requiredFields';
import { getLocalizedDate } from 'clerk_common/stringification/dates';
import { CompletedFreightOrderTemplate } from 'clerk_common/templates/freight_order/types';
import { getValue, toValues } from 'clerk_common/templates/getValue';
import { mutateByFieldTypeSync } from 'clerk_common/templates/mutate/mutateSync';
import { doesPathMatch } from 'clerk_common/templates/path';
import {
  FormValueChild,
  ListValuedIntermediateTemplateField,
} from 'clerk_common/templates/types';
import { Address } from 'clerk_common/types/address';
import { flattenDeep, isEqual, isNil, uniq } from 'lodash';
import {
  filterAndStripRequiredFields,
  validateAddressHasRequiredFields,
} from './requiredFields';
import { QuoteFormValues, Stop, StopValue } from './types';

export const getValidateAddress = (
  addressPath: string,
  integrationConfigs: IntegrationConfig[]
): ((address: Address) => boolean) => {
  return (address) => {
    if (!integrationConfigs.length) return true;

    const genericName = getGenericName(addressPath);
    const requiredFields =
      combineIntegrationConfigsRequiredFields(integrationConfigs);

    const filteredRequiredFields = filterAndStripRequiredFields(
      requiredFields,
      genericName
    );
    if (filteredRequiredFields.length === 0) return true;

    return validateAddressHasRequiredFields(address, filteredRequiredFields);
  };
};

export function getGenericName(input: string): string {
  return input.replace(/\d+/g, '<n>');
}

export const combineIntegrationConfigsRequiredFields = (
  configs: IntegrationConfig[]
): RequiredField[] => {
  const requiredFields = configs.flatMap(
    (config) => config.requiredFields || [...DEFAULT_REQUIRED_FIELDS]
  );
  return uniq(requiredFields);
};

export const getIsRequiredField = (
  name: string,
  integrationConfigs: IntegrationConfig[]
): boolean => {
  if (!integrationConfigs.length) false;
  // TODO(parlato): We should get required fields for the quote
  // from the backend so we can properly override them.
  const requiredFields = flattenDeep(
    combineIntegrationConfigsRequiredFields(integrationConfigs)
  );

  const genericName = getGenericName(name);
  return requiredFields.includes(genericName);
};

const stopValueToFormValue = (stop: StopValue): Stop => {
  const date = stop.date ? getLocalizedDate(stop.date) : undefined;
  return {
    ...stop,
    date,
  };
};

// TODO(jacob): Replace the `any` when Max's toValues typing PR lands.
export const parseQuoteFormValuesFromJSON = (values: any): QuoteFormValues => {
  return {
    freightMode: values.freightMode,
    pickup: stopValueToFormValue(values.pickup),
    stops: values.stops.map(stopValueToFormValue),
    equipmentType: values.equipmentType,
    commodity: values.commodity,
    weight: values.weight,
    notes: values.notes,
  };
};

export const mapExtractedDataToQuoteFormValues = (
  extractedData?: CompletedFreightOrderTemplate
): QuoteFormValues => {
  const values = toValues(extractedData);
  return parseQuoteFormValuesFromJSON(values);
};

export const mutateFieldByPath = (
  completedTemplate: CompletedFreightOrderTemplate,
  name: string,
  value: string | Address
) => {
  return mutateByFieldTypeSync(completedTemplate as FormValueChild, {
    field: (child, path) => {
      const currentValue = getValue(child);
      const valueIsDifferent = !isEqual(currentValue, value);
      const pathMatches =
        !isNil(path) &&
        doesPathMatch(path, name, completedTemplate as FormValueChild);
      if (valueIsDifferent && pathMatches) {
        const updated = {
          ...child,
          _corrected: value,
        } as FormTemplateChildConfigs;
        return updated;
      }

      return child;
    },
  }) as CompletedFreightOrderTemplate;
};

const getIdxs = (child: ListValuedIntermediateTemplateField): string[] => {
  return child._idxs ?? child._value.map((_, i) => `_value.${i}`);
};

export const removeArrayElement = (
  completedTemplate: CompletedFreightOrderTemplate,
  name: string,
  index: number
) => {
  return mutateByFieldTypeSync(completedTemplate as FormValueChild, {
    list: (child, path) => {
      const pathMatches =
        !isNil(path) &&
        doesPathMatch(path, name, completedTemplate as FormValueChild);
      if (pathMatches) {
        const baseIdxs = getIdxs(child);
        const updatedIdxs = baseIdxs.filter((_, i) => i !== index);
        return {
          ...child,
          _idxs: updatedIdxs,
        };
      }

      return child;
    },
  }) as CompletedFreightOrderTemplate;
};

export const addArrayElement = (
  completedTemplate: CompletedFreightOrderTemplate,
  name: string,
  idx: number,
  value: FormValue
) => {
  return mutateByFieldTypeSync(completedTemplate as FormValueChild, {
    list: (child, path) => {
      const pathMatches =
        !isNil(path) &&
        doesPathMatch(path, name, completedTemplate as FormValueChild);
      if (pathMatches) {
        const baseIdxs = getIdxs(child);
        const updatedIdxs = [...baseIdxs];
        const addedIdx = child._added?.length ?? 0;
        updatedIdxs.splice(idx, 0, `_added.${addedIdx}`);
        return {
          ...child,
          _idxs: updatedIdxs,
          _added: [...(child._added ?? []), value],
        };
      }

      return child;
    },
  }) as CompletedFreightOrderTemplate;
};
