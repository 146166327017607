import { Tooltip } from '@shared/components/tooltip';
import { useLogosContext } from '@shared/contexts/pluginContexts/LogosURLsContext';
import { RatingMethod } from '@shared/generated/graphql';

export const RateLogo = ({
  rateMethod,
  integrationImageOverride,
  integrationNameOverride,
}: {
  rateMethod?: RatingMethod;
  integrationImageOverride?: string;
  integrationNameOverride?: string;
}) => {
  const logos = useLogosContext();
  if (!rateMethod) return null;

  const gsDefaultName = 'Greenscreens.ai';
  const datDefaultName = 'DAT';
  const sunsetDefaultName = 'Sunset';
  const tabiDefaultName = 'Tabi';
  const wernerDefaultName = 'Werner';
  const arcbestDefaultName = 'ArcBest';
  const echoDefaultName = 'Echo';
  const transfixDefaultName = 'Transfix';
  const bitfreighterDefaultName = 'Bitfreighter';
  const tooltipContent = (name: string) =>
    `Rate sourced from ${integrationNameOverride ?? name} `;

  switch (rateMethod) {
    case RatingMethod.DAT:
      return (
        <Logo
          src={integrationImageOverride ?? logos.DAT}
          tooltip={tooltipContent(datDefaultName)}
          width={48}
        />
      );
    case RatingMethod.GREENSCREENS:
      return (
        <Logo
          src={integrationImageOverride ?? logos.GREENSCREENS}
          tooltip={tooltipContent(gsDefaultName)}
        />
      );
    case RatingMethod.SUNSET:
      return (
        <Logo
          src={integrationImageOverride ?? logos.SUNSET}
          tooltip={tooltipContent(sunsetDefaultName)}
        />
      );
    case RatingMethod.TABI:
      return (
        <Logo
          src={integrationImageOverride ?? logos.TABI}
          tooltip={tooltipContent(tabiDefaultName)}
        />
      );
    case RatingMethod.WERNER:
      return (
        <Logo
          src={integrationImageOverride ?? logos.WERNER}
          tooltip={tooltipContent(wernerDefaultName)}
        />
      );
    case RatingMethod.ARCBEST_TL_QUOTING:
      return (
        <Logo
          src={integrationImageOverride ?? logos.ARCBEST}
          tooltip={tooltipContent(arcbestDefaultName)}
        />
      );
    case RatingMethod.ECHO:
      return (
        <Logo
          src={integrationImageOverride ?? logos.ECHO}
          tooltip={tooltipContent(echoDefaultName)}
        />
      );
    case RatingMethod.TRANSFIX:
      return (
        <Logo
          imgClassName="rounded-[4px]"
          src={integrationImageOverride ?? logos.TRANSFIX}
          tooltip={tooltipContent(transfixDefaultName)}
        />
      );
    case RatingMethod.BITFREIGHTER:
      return (
        <Logo
          imgClassName="rounded-[4px]"
          src={integrationImageOverride ?? logos.BITFREIGHTER}
          tooltip={tooltipContent(bitfreighterDefaultName)}
        />
      );
    default:
      return null;
  }
};

type LogoProps = {
  src: string;
  tooltip: string;
  width?: number;
  imgClassName?: string;
};
function Logo({ src, tooltip, imgClassName, width = 32 }: LogoProps) {
  return (
    <Tooltip content={tooltip} isInstant>
      <div className="rounded-md border p-[2px]">
        <img className={imgClassName} width={width} src={src} alt={tooltip} />
      </div>
    </Tooltip>
  );
}
