import { LaneFilter, QuoteBidStatus } from '@shared/generated/graphql';
import { Quote } from '@shared/types/quote';
import { getEquipmentTypesFromFreightOrder } from 'clerk_common/templates/freight_order/fieldExtractors/fieldExtractors';
import { useEffect, useState } from 'react';

export type LaneHistoryFilters = {
  laneFilter?: LaneFilter;
  equipmentTypes?: string[];
  originatorIds?: string[];
  statuses?: QuoteBidStatus[];
};

type UseGetQuotesState = {
  filters: LaneHistoryFilters;
  setFilters: (filters: LaneHistoryFilters) => void;
};

const getLaneFilter = (quote: Quote): LaneFilter | undefined => {
  const route = quote.routeGeoCoordinates;
  if (!route || route?.length < 1) return undefined;
  const destinationIndex = route.length - 1;
  return {
    destination: {
      latitude: route[destinationIndex].latitude,
      longitude: route[destinationIndex].longitude,
      radiusMiles: 50,
    },
    origin: {
      latitude: route[0].latitude,
      longitude: route[0].longitude,
      radiusMiles: 50,
    },
  };
};

const getEquipmentTypes = (quote: Quote): string[] => {
  if (!quote?.extractedData) return [];
  return getEquipmentTypesFromFreightOrder(quote.extractedData);
};

export const useLaneHistoryFilters = (quote: Quote): UseGetQuotesState => {
  const [filters, setFilters] = useState<LaneHistoryFilters>(
    toDefaultFilters(quote)
  );

  useEffect(() => {
    setFilters(toDefaultFilters(quote));
  }, [quote]);

  return {
    filters,
    setFilters,
  };
};

const toDefaultFilters = (quote: Quote): LaneHistoryFilters => {
  const originatorId = quote.originator?.id;
  return {
    originatorIds: originatorId ? [originatorId] : undefined,
    statuses: [QuoteBidStatus.WON, QuoteBidStatus.LOST, QuoteBidStatus.QUOTED],
    laneFilter: getLaneFilter(quote),
    equipmentTypes: getEquipmentTypes(quote),
  };
};
