import { RateMetadata } from '@shared/types/quote';

export const extractTransfixPercentiles = (metadata: RateMetadata) => {
  const costKeys = Object.keys(metadata.response.model_response).filter((key) =>
    key.startsWith('cost_')
  );

  return costKeys.map((k) => {
    const isMean = k === 'cost_mean';
    return {
      label: isMean ? 'Mean' : `${k.replace('cost_', '')}th %`,
      key: k,
      isPercentile: !isMean,
      opacity: isMean ? '100' : k.replace('cost_', ''),
      value: metadata.response.model_response[k],
    };
  });
};
