import { Separator, Text } from '@shared/components';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { useGetConversationExternalMessagesQuery } from '@shared/generated/graphql';
import { toConversationEvents } from '@shared/graphql/fromFragments/externalMessage';
import {
  ConversationContributionRole,
  ConversationEvent,
  ConversationEventType,
  EmailContribution,
  SystemMessageEvent,
  UnknownEvent,
  VoiceContribution,
} from '@shared/types/conversation';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import {
  formatAbsoluteTime,
  formatElapsedTime,
} from 'clerk_common/stringification/times';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';

const ROOT = makeRootClassName('ConversationTranscript');
const el = makeElementClassNameFactory(ROOT);

type ConversationMetadata = {
  id: string;
  createdAt: Date;
};

export const ConversationTranscript = ({
  conversation,
}: {
  conversation: ConversationMetadata;
}) => {
  const { defaultOrgId } = useMeContext();
  const { data } = useGetConversationExternalMessagesQuery({
    variables: {
      input: {
        conversationId: conversation.id,
        ...(defaultOrgId && { organizationIds: [defaultOrgId] }),
      },
    },
    // NOTE(parlato): We should definitely not be polling the external
    // messages table every second
    pollInterval: 1 * 1000,
  });
  const msgs = toConversationEvents(data);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [msgs]);

  return (
    <div ref={containerRef} className={el`conversation-events`}>
      {msgs?.map((e) => (
        <ConversationEventComponent
          key={e.id}
          event={e}
          conversationStartTime={conversation.createdAt}
        />
      ))}
      {msgs.length === 0 && (
        <Text type="body-xs" className="text-gray-500">
          Waiting for someone to say something...
        </Text>
      )}
    </div>
  );
};

type ConversationEventComponentProps = {
  event: ConversationEvent;
  conversationStartTime: Date;
};
function ConversationEventComponent(p: ConversationEventComponentProps) {
  switch (p.event.type) {
    case ConversationEventType.VOICE_CONTRIBUTION:
      return (
        <VoiceContributionBubble
          contribution={p.event as VoiceContribution}
          conversationStartTime={p.conversationStartTime}
        />
      );
    case ConversationEventType.EMAIL_CONTRIBUTION:
      return (
        <EmailContributionBubble contribution={p.event as EmailContribution} />
      );
    case ConversationEventType.SYSTEM_MESSAGE:
      return <SystemMessageBubble message={p.event as SystemMessageEvent} />;
    case ConversationEventType.UNKNOWN:
      return <UnknownEventBubble event={p.event as UnknownEvent} />;
    default:
      return null;
  }
}

function roleToClassName(role: ConversationContributionRole) {
  switch (role) {
    case ConversationContributionRole.USER:
      return 'USER';
    case ConversationContributionRole.ASSISTANT:
      return 'ASSISTANT';
    case ConversationContributionRole.SYSTEM:
      return 'SYSTEM';
    default:
      return '';
  }
}

type VoiceContributionBubbleProps = {
  contribution: VoiceContribution;
  conversationStartTime: Date;
};
const VoiceContributionBubble = ({
  contribution,
  conversationStartTime,
}: VoiceContributionBubbleProps) => {
  const roleClassName = roleToClassName(contribution.role);
  const className = clsx(el`voice-contribution`, {
    [roleClassName]: true,
  });

  return (
    <div className={className}>
      <Text key={contribution.id} type="body-xs">
        {contribution.transcript}
      </Text>
      <Text type="body-xs" className="text-end">
        {formatElapsedTime(conversationStartTime, contribution.createdAt)}
      </Text>
    </div>
  );
};

type EmailContributionBubbleProps = {
  contribution: EmailContribution;
};
const EmailContributionBubble = ({
  contribution,
}: EmailContributionBubbleProps) => {
  return (
    <div className={el`email-contribution`}>
      <div className={el`email-header`}>
        <div className={el`email-header-row`}>
          <Text type="body-sm" isHeavy>
            {contribution.subject ?? '<no subject>'}
          </Text>
          <Text type="body-sm" className="text-end">
            {formatAbsoluteTime(contribution.createdAt)}
          </Text>
        </div>
        <div className={el`email-header-row`}>
          <Text type="body-sm">From: {contribution.sender}</Text>
        </div>
        <div className={el`email-header-row`}>
          <Text type="body-sm">To: {contribution.toRecipients.join(', ')}</Text>
        </div>
      </div>
      <Separator />
      <iframe
        className={el`email-iframe`}
        srcDoc={contribution.sanitizedHtmlBody}
      />
    </div>
  );
};

type UnknownEventBubbleProps = {
  event: UnknownEvent;
};
const UnknownEventBubble = ({ event }: UnknownEventBubbleProps) => {
  return (
    <div className={el`unknown-event`}>
      <Text key={event.id} type="body-xs">
        Unknown or unsupported event
      </Text>
      <Text type="body-xs" className="text-end">
        {formatAbsoluteTime(event.createdAt)}
      </Text>
    </div>
  );
};

type SystemMessageBubbleProps = {
  message: SystemMessageEvent;
};
const SystemMessageBubble = ({ message }: SystemMessageBubbleProps) => {
  const strippedMessage = message.message.replace(/<SYSTEM MESSAGE>/g, '');
  return (
    <div className={el`system-message`}>
      <Text key={message.id} type="body-xs">
        {strippedMessage}
      </Text>
      <Text type="body-xs" className="text-end">
        {formatAbsoluteTime(message.createdAt)}
      </Text>
    </div>
  );
};
