import {
  OrderEvent,
  OriginatorStatus,
  ProcessingStatus,
} from '@shared/generated/graphql';
import { Document } from '@shared/types/document';
import { JobStatus } from '@shared/types/job';
import { JSONObject, RequiredField } from 'clerk_common';

// TODO(mike): Deduplicate this from the backend definition by
//  putting it in a shared file.
export enum IntegrationType {
  DAT = 'DAT',
  ECHO = 'ECHO',
  EVANS = 'EVANS',
  GREENSCREENS = 'GREENSCREENS',
  MODE = 'MODE',
  NFI = 'NFI',
  ORDERFUL = 'ORDERFUL',
  STEDI = 'STEDI',
  SUNSET = 'SUNSET',
  TAI = 'TAI',
  TEST = 'TEST',
  TURVO = 'TURVO',
}

export enum DATForecastPeriod {
  EIGHT_DAYS = '8DAYS',
  THIRTY_FIVE_DAYS = '35DAYS',
  FIFTY_TWO_WEEKS = '52WEEKS',
}

// TODO(parlato): This needs to come from a shared project
// like clerk_common
export type IntegrationConfig = {
  orderType: string;
  requiredFields: RequiredField[];
  integrationType?: IntegrationType;
  isLive?: boolean;
  forecastPeriod?: DATForecastPeriod;
  shouldFetchMarketConditions?: boolean;
  integrationImageOverride?: string;
  integrationNameOverride?: string;
};
export type IntegrationConfigs = {
  integrations: IntegrationConfig[];
};
export type ConfigOverride = Partial<IntegrationConfig>;
export type ConfigOverrides = {
  integrations?: ConfigOverride[];
};

export type Order = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  documents: Document[];
  orderEvents: OrderEvent[];
  organization: {
    id: string;
    integrationConfigs: IntegrationConfigs;
  };
  job: {
    id: string;
    emailMetadata: JSONObject | null;
  };
  originator: {
    id?: string;
    name?: string;
    transmitIds: string[];
    status: OriginatorStatus;
    configOverrides?: ConfigOverrides;
  };
  transmitId?: string;
  primaryReference?: string;
  extractedData: JSONObject | null;
  user?: {
    id: string;
    email?: string;
  };
  isOnlyOrderInJob: boolean;
  parentJobStatus: JobStatus;
  status?: ProcessingStatus;
  hasBeenSubmitted?: boolean;
  isRedacted: boolean;
  externalDeepLink?: string | null;
  externalId?: string | null;
};

export type OrderPreview = {
  id: string;
  createdAt: Date;
  createdAtDisplay: Date;
  updatedAt: Date;
  primaryReference?: string;
  originator: {
    id?: string;
    name?: string;
  };
  user?: {
    id: string;
    email?: string;
  };
  status?: ProcessingStatus;
  hasBeenSubmitted: boolean;
  isRedacted: boolean;
};
