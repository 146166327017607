import { QuoteSummaryFragment, RatingMethod } from '@shared/generated/graphql';
import clsx from 'clsx';
import { el } from './QuoteRow';

type CarrierRateMetadataProps = {
  carrierRate: QuoteSummaryFragment['carrierRate'];
};

export const CarrierRateMetadata = ({
  carrierRate,
}: CarrierRateMetadataProps) => {
  switch (carrierRate?.ratingMethod) {
    case RatingMethod.ECHO:
      return <EchoRateMetadata carrierRate={carrierRate} />;
    default:
      return null;
  }
};

const EchoRateMetadata = ({ carrierRate }: CarrierRateMetadataProps) => {
  const conf =
    carrierRate?.metadata?.response?.rate?.buyRateConfidence?.confidenceScore;
  if (!conf) return null;

  const variant = getConfidenceBin(conf);

  return (
    <p className={clsx(el`confidence-badge`, `variant-${variant}`)}>
      Conf.&nbsp;{conf}
    </p>
  );
};

const getConfidenceBin = (confidence: number) => {
  if (confidence >= 4) return 'HIGH';
  if (confidence >= 3) return 'MEDIUM';
  return 'LOW';
};
