import { ExpandablePanel } from '@shared/components/expandable-panel';
import { MarketConditionsContainer } from '@shared/components/money-form/components/market-conditions';
import { useRateErrorsContext } from '@shared/components/money-form/contexts/RateErrorContext';
import { Text } from '@shared/components/text';
import { RatingMethod } from '@shared/generated/graphql';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { IntegrationConfig, IntegrationConfigs } from '@shared/types/order';
import { Quote } from '@shared/types/quote';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { getMileageRate } from '@shared/utils/rates/rateDetails';
import { ratingMethodToLabel } from '@shared/utils/rates/rates';
import { usdFormatterNoCents } from 'clerk_common/stringification/numbers';
import { useEffect, useState } from 'react';
import { useMarketConditionsContext } from '../../market-conditions/contexts/MarketConditionsContext';
import { ratingMethodToIntegrationType } from '../../market-conditions/MarketConditionsContainer';
import { RateDetail } from '../../rate-detail/RateDetail';
import { RateLogo } from '../../RateLogo';
import { LinkToRateSource } from '../components/LinkToRateSource/LinkToRateSource';
import { WidgetBody } from '../components/WidgetBody/WidgetBody';
import { WidgetHeader } from '../components/WidgetHeader/WidgetHeader';
import { useSetBuyRateId } from '../hooks/useSetBuyRate';
import { GroupedRate } from './types';

const ROOT = makeRootClassName('GroupedRates');
const el = makeElementClassNameFactory(ROOT);

export const getRatingMethodIntegrationConfig = (
  ratingMethod: RatingMethod,
  integrationConfigs?: IntegrationConfigs
): IntegrationConfig | undefined => {
  const integrationType = ratingMethodToIntegrationType(ratingMethod);
  if (!integrationConfigs?.integrations?.length) return undefined;
  return integrationConfigs.integrations.find(
    (ic) => ic.integrationType === integrationType
  );
};

type GroupedRatesProps = {
  rates: GroupedRate[];
  ratingMethod: RatingMethod;
  onExpandAnyRateWidget: (ratingMethod: RatingMethod) => void;
  focusedRatingWidget?: RatingMethod;
  preventRateComparisons: boolean;
  quote: Quote;
  rateSourceUrl?: string;
  defaultExpanded?: boolean;
  defaultRate?: GroupedRate;
};

// TODO(mike): Break this apart into components for each rating method that's
// currently mixed into this one.
export const GroupedRates = (p: GroupedRatesProps) => {
  const { rateErrors } = useRateErrorsContext();
  const defaultExpanded =
    p.defaultExpanded || Boolean(rateErrors[p.ratingMethod]) || false;
  const [expanded, setExpanded] = useState(defaultExpanded);
  const integrationConfig = getRatingMethodIntegrationConfig(
    p.ratingMethod,
    p.quote.organization.integrationConfigs
  );
  const { shouldFetchMarketConditions } = integrationConfig || {};
  const { fetchMarketConditionsMutation, loading } =
    useMarketConditionsContext();
  const { track } = useAnalytics();

  useEffect(() => {
    if (expanded && !loading && shouldFetchMarketConditions) {
      // NOTE(parlato): We lazy fetch market conditions only once
      // the user expands the panel (all integrations with market
      // conditions enabled will be fetched)
      fetchMarketConditionsMutation();
    }
  }, [expanded, shouldFetchMarketConditions]);

  const { setBuyRateId, loading: loadingSetRate } = useSetBuyRateId({
    quote: p.quote,
  });

  useEffect(() => {
    // NOTE(parlato): This is due to requirements from our DAT agreement which
    // stipulate that we cannot show rates from other providers when showing DAT
    if (p.preventRateComparisons && p.focusedRatingWidget !== p.ratingMethod) {
      setExpanded(false);
    }
  }, [p.focusedRatingWidget, p.preventRateComparisons]);

  const handleExpandChange = (expanded: boolean) => {
    track(`Rate Widget ${expanded ? 'Expanded' : 'Collapsed'}`, {
      name: ratingMethodToLabel(p.ratingMethod),
      quoteId: p.quote.id,
    });
    expanded && p.onExpandAnyRateWidget(p.ratingMethod);
    setExpanded(expanded);
  };

  const header = (
    <WidgetHeader
      title={
        integrationConfig?.integrationNameOverride ??
        ratingMethodToLabel(p.ratingMethod)
      }
      logo={
        <RateLogo
          rateMethod={p.ratingMethod}
          integrationNameOverride={integrationConfig?.integrationNameOverride}
          integrationImageOverride={integrationConfig?.integrationImageOverride}
        />
      }
      expanded={expanded}
      rateError={rateErrors[p.ratingMethod]}
      ratePreview={
        p.preventRateComparisons ? null : (
          <RatePreview defaultRate={p.defaultRate} />
        )
      }
    />
  );

  return (
    <div className={ROOT}>
      <ExpandablePanel
        setExpanded={handleExpandChange}
        expanded={expanded}
        defaultExpanded={defaultExpanded}
        summary={header}
      >
        <WidgetBody
          integrationName={
            integrationConfig?.integrationNameOverride ??
            ratingMethodToLabel(p.ratingMethod)
          }
          rateError={rateErrors[p.ratingMethod]}
        >
          {p.rates.map((rate) => {
            return (
              <RateDetail
                onPress={() => setBuyRateId(rate.id)}
                isDisabled={loadingSetRate}
                key={rate.key}
                label={integrationConfig?.integrationNameOverride ?? rate.label}
                rate={rate.rate}
                ratingMethod={p.ratingMethod}
                metadata={rate.metadata}
              />
            );
          })}
          {shouldFetchMarketConditions && (
            <MarketConditionsContainer
              integrationType={ratingMethodToIntegrationType(p.ratingMethod)}
            />
          )}
          <LinkToRateSource url={p.rateSourceUrl} />
        </WidgetBody>
      </ExpandablePanel>
    </div>
  );
};

type RatePreviewProps = {
  defaultRate?: GroupedRate;
};
function RatePreview({ defaultRate }: RatePreviewProps) {
  const mileageRate = getMileageRate({ metadata: defaultRate?.metadata });

  if (!defaultRate) return null;
  return (
    <div className={el`rate`}>
      <div className={el`rate-dollars`}>
        <Text isHeavy type="body-xs">
          {usdFormatterNoCents.format(defaultRate.rate)}
        </Text>
      </div>
      {mileageRate && (
        <div className={el`rate-mileage`}>
          <Text type="custom">{mileageRate}</Text>
        </div>
      )}
    </div>
  );
}
