import { CircularProgress } from '@shared/components/plugin-components';
import { IntegrationType, RatingMethod } from '@shared/generated/graphql';
import { useMarketConditionsContext } from './contexts/MarketConditionsContext';
import { MarketConditions } from './MarketConditions';

export const ratingMethodToIntegrationType = (
  ratingMethod: RatingMethod
): IntegrationType | null => {
  switch (ratingMethod) {
    case RatingMethod.DAT:
      return IntegrationType.DAT;
    case RatingMethod.GREENSCREENS:
      return IntegrationType.GREENSCREENS;
    case RatingMethod.SUNSET:
      return IntegrationType.SUNSET;
    case RatingMethod.TABI:
      return IntegrationType.TABI;
    case RatingMethod.WERNER:
      return IntegrationType.WERNER;
    case RatingMethod.ARCBEST_TL_QUOTING:
      return IntegrationType.ARCBEST_TL_QUOTING;
    case RatingMethod.ECHO:
      return IntegrationType.ECHO;
    case RatingMethod.TRANSFIX:
      return IntegrationType.TRANSFIX_RATING;
    case RatingMethod.BITFREIGHTER:
      return IntegrationType.BITFREIGHTER_RATING;
    default:
      return null;
  }
};

interface MarketConditionsContainerProps {
  integrationType: IntegrationType | null;
}

export const MarketConditionsContainer = (
  p: MarketConditionsContainerProps
) => {
  const { marketConditions, loading, forceFetchMarketConditionsMutation } =
    useMarketConditionsContext();

  const marketConditionsForIntegration = marketConditions?.filter(
    (mc) =>
      mc.integrationType === p.integrationType && (mc.stop || mc.errorMessage)
  );

  if (!loading && !marketConditionsForIntegration?.length) return null;

  return (
    <div>
      {loading ? (
        <div className="flex items-center justify-center border-t p-4">
          <CircularProgress size="sm" />
        </div>
      ) : (
        <MarketConditions
          handleRefresh={forceFetchMarketConditionsMutation}
          marketConditions={marketConditionsForIntegration || []}
        />
      )}
    </div>
  );
};
