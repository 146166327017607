import { Button, CircularProgress, Text } from '@shared/components';
import {
  Autocomplete,
  Option,
  Select,
  TextField,
  useLoadInitialValues,
} from '@shared/components/react-hook-form';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { PlaceDetailsFormValues } from '@shared/features/place-details-section/types';
import usePlaceDetails from '@shared/graphql/forms/place/usePlaceDetails';
import useOriginators from '@shared/plugin/hooks/useOriginators';
import { getPlaceTagOptions } from '@shared/types/place';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { getStateOptions, stateCodes } from 'clerk_common/geography';
import clsx from 'clsx';
import { ReactElement, useState } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';

export type PlaceDetailsFormProps = {
  isLoading: boolean;
  onSubmit: (data: PlaceDetailsFormValues) => void;
  initialFormData: PlaceDetailsFormValues;
  onDiscard: () => void;
  isSubmitting: boolean;
};

const ROOT = makeRootClassName('PlaceDetailsForm');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_FORM_VALUES = {} as const;

export function PlaceDetailsForm(props: PlaceDetailsFormProps): ReactElement {
  const p = { ...DEFAULT_FORM_VALUES, ...props.initialFormData };
  const { me } = useMeContext();
  const { control, handleSubmit, reset, watch } =
    useForm<PlaceDetailsFormValues>({
      defaultValues: p,
    });
  useLoadInitialValues(reset, p);
  const { onSubmit, onDiscard, isLoading } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'aliases',
  });

  const submitHandler: SubmitHandler<PlaceDetailsFormValues> = (data) => {
    onSubmit(data);
  };

  const selectedCountry = watch('address.country');
  const tagOptions = getPlaceTagOptions();

  const organizationId = me?.organizationsDetails[0].id;
  const { originators, setSearchTerm: setOriginatorSearchTerm } =
    useOriginators({
      organizationId,
    });
  const hasMultipleOrgs = (me?.organizationsDetails?.length ?? 0) > 1;
  const originatorOptions = [
    { id: null, label: 'None' },
    ...originators.map((o) => ({
      id: o.id,
      label: hasMultipleOrgs ? `${o.name} (${o.organization.name})` : o.name,
    })),
  ];

  return (
    <div className={clsx(ROOT)}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className={el`place-details`}>
          <Text type="body-md" isHeavy>
            Place Details
          </Text>
          <TextField
            label="Name"
            name="name"
            size="small"
            control={control}
            type="text"
            rules={{
              required: true,
            }}
            isDisabled={isLoading}
          />
          <TextField
            label="Location Code"
            name="locationCode"
            size="small"
            control={control}
            type="text"
            isDisabled={isLoading}
          />
          <TextField
            label="Address Line One"
            name="address.addressOne"
            size="small"
            control={control}
            type="text"
            rules={{
              required: true,
            }}
            isDisabled={isLoading}
          />
          <TextField
            label="Address Line Two"
            name="address.addressTwo"
            size="small"
            control={control}
            type="text"
            rules={{
              required: false,
            }}
            isDisabled={isLoading}
          />
          <div className={el`side-by-side-fields`}>
            <TextField
              label="City"
              name="address.city"
              size="small"
              control={control}
              type="text"
              rules={{
                required: true,
              }}
              isDisabled={isLoading}
            />
            <Select
              label="State"
              name="address.state"
              size="small"
              control={control}
              rules={{
                required: true,
              }}
              isDisabled={isLoading}
              renderValue={(s: { label: string; value: string }) => s.label}
            >
              {getStateOptions(selectedCountry).map((stateCode) => (
                <Option key={stateCode.label} value={stateCode}>
                  {stateCode.label}
                </Option>
              ))}
            </Select>
          </div>
          <div className={el`side-by-side-fields`}>
            <TextField
              label="Zipcode"
              name="address.zip"
              size="small"
              control={control}
              type="text"
              rules={{
                required: true,
              }}
              isDisabled={isLoading}
            />
            <Select
              label="Country"
              name="address.country"
              size="small"
              control={control}
              rules={{
                required: true,
              }}
              isDisabled={isLoading}
              renderValue={(c: string) => c}
            >
              {Object.keys(stateCodes).map((country) => (
                <Option key={country} value={country}>
                  {country}
                </Option>
              ))}
            </Select>
          </div>
          <TextField
            label="Primary Email"
            name="primaryEmail"
            size="small"
            control={control}
            type="text"
            isDisabled={isLoading}
          />
          <Autocomplete
            multiple
            label="Tags"
            name="tags"
            size="small"
            control={control}
            rules={{
              required: false,
            }}
            isDisabled={isLoading}
            options={tagOptions}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
          />
          <Autocomplete
            label="Customer (optional)"
            name="originator"
            control={control}
            onInputChange={(_, value) => setOriginatorSearchTerm(value)}
            options={originatorOptions}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            isDisabled={props.isSubmitting}
          />
          <div className={el`aliases`}>
            <Text type="body-md" isHeavy>
              Aliases
            </Text>
            {fields.map((field, index) => (
              <div key={field.id} className={el`alias`}>
                <TextField
                  name={`aliases.${index}.value`}
                  size="small"
                  control={control}
                  type="text"
                  rules={{
                    required: true,
                  }}
                  isDisabled={isLoading}
                  fieldWrapperClassName={el`alias-field-wrapper`}
                />
                <Button
                  variant="secondary"
                  type="button"
                  onPress={() => remove(index)}
                >
                  Remove
                </Button>
              </div>
            ))}
            <Button
              variant="secondary"
              size="xs"
              type="button"
              onPress={() => append({ value: '' })}
            >
              Add Alias
            </Button>
          </div>
        </div>
        <div className={el`footer`}>
          <Button variant="secondary" onPress={onDiscard} type="button">
            Discard
          </Button>
          <Button variant="primary" type="submit" isDisabled={isLoading}>
            Save Changes
          </Button>
        </div>
      </form>
    </div>
  );
}

interface PlaceDetailsFormContainerProps {
  placeId: string;
  onDiscard: () => void;
}

export default function PlaceDetailsFormContainer({
  placeId,
  onDiscard,
}: PlaceDetailsFormContainerProps) {
  const { initialFormData, isLoading, isUpdating, onUpdate } = usePlaceDetails({
    placeId,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (data: PlaceDetailsFormValues) => {
    setIsSubmitting(true);
    await onUpdate(data);
    onDiscard();
    setIsSubmitting(false);
  };

  return (
    <>
      {isLoading ? (
        <div className="flex w-full justify-center">
          <CircularProgress className="my-[300px]" />
        </div>
      ) : (
        <PlaceDetailsForm
          initialFormData={initialFormData}
          isLoading={isUpdating}
          onSubmit={onSubmit}
          onDiscard={onDiscard}
          isSubmitting={isSubmitting}
        />
      )}
    </>
  );
}
