import { capitalize } from 'lodash';

import { PlaceCreationProvenance, PlaceTag } from '@shared/generated/graphql';

export type Place = {
  id: string;
  name: string;
  data?: {
    primaryEmail: string;
  };
  address?: {
    addressOne: string;
    addressTwo?: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  };
  locationCode: string;
  originator: {
    id: string;
    name: string;
  };
  tags: PlaceTag[];
  aliases: string[];
  creationProvenance: PlaceCreationProvenance;
};

export const getCityStateFromPlace = (place?: Place) => {
  if (!place) return '';
  if (place?.address?.city || place?.address?.state) {
    return `${place?.address?.city ? place?.address?.city + ',' : ''} ${
      place?.address?.state ? place?.address?.state : ''
    }`;
  } else {
    return '';
  }
};

export const getPlaceTagOptions = () =>
  Object.values(PlaceTag).map((t) => ({
    label: capitalize(t),
    value: t,
  }));
