import {
  Button,
  FormDisplayTableRow,
  Text,
  TextChip,
} from '@shared/components';
import { PlaceTag } from '@shared/generated/graphql';
import { Place } from '@shared/types/place';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@shared/utils';
import {
  formatAddressLineOne,
  formatAddressLineTwo,
} from 'clerk_common/stringification/address';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';

export type PlaceDetailsDisplayProps = StyleProps & {
  onEdit: () => void;
  place: Place;
};

const ROOT = makeRootClassName('PlaceDetailsDisplay');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function PlaceDetailsDisplay(props: PlaceDetailsDisplayProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const { place } = p;

  return (
    <div className={clsx(ROOT, p.className)}>
      <div className={el`content`}>
        <div className={el`place-details`}>
          <Text type="body-md" isHeavy>
            Place Details
          </Text>
          <Button
            icon={<AiOutlineEdit size={20} />}
            size="small"
            isGhost
            onPress={() => p.onEdit()}
          />
        </div>
        <FormDisplayTableRow displayText="Name" value={place.name} />
        <FormDisplayTableRow
          displayText="Address"
          value={formatAddressLineOne(place.address)}
        />
        <FormDisplayTableRow
          displayText=""
          value={formatAddressLineTwo(place.address)}
        />
        <FormDisplayTableRow
          displayText="Location Code"
          value={place.locationCode}
        />
        <FormDisplayTableRow
          displayText="Primary Email"
          value={place.data?.primaryEmail || 'None'}
        />
        <FormDisplayTableRow
          displayText="Tags"
          value={<PlaceTagChipList tags={place.tags} emptyText="None" />}
        />
        <FormDisplayTableRow
          displayText="Customer"
          value={place.originator?.name ?? 'None'}
        />
        <FormDisplayTableRow
          displayText="Aliases"
          value={place.aliases.length > 0 ? place.aliases.join(', ') : 'None'}
        />
      </div>
    </div>
  );
}

type PlaceTagChipListProps = {
  tags: PlaceTag[];
  emptyText?: string;
};
export function PlaceTagChipList(p: PlaceTagChipListProps) {
  if (p.emptyText && p.tags.length === 0) {
    return <Text type="body-sm">{p.emptyText}</Text>;
  }
  return (
    <div className={el`tags`}>
      {p.tags.map((tag, idx) => (
        <TextChip key={idx} text={tag} />
      ))}
    </div>
  );
}

export default PlaceDetailsDisplay;
