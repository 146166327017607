import {
  LineItemType,
  MoneyFormRateLineItem,
  MoneyFormValues,
} from '@shared/components/money-form/components/types';
import { RateLineItem, RatingMethod } from '@shared/generated/graphql';
import {
  CarrierRate,
  CustomerRate,
  MileageRate,
  RateMetadata,
} from '@shared/types/quote';
import { MANUAL_ADJUSTMENT_RATE_COMPONENT_DESCRIPTION } from 'clerk_common/constants';
import { enumToString } from 'clerk_common/enums';
import { formatNumber } from 'clerk_common/stringification/numbers';
import { filterRateComponents, Rate, RateComponent } from 'clerk_common/types';
import { RateComponentType } from 'clerk_common/types/pricingStrategy';
import { isNil } from 'lodash';
import { v4 } from 'uuid';

const AVERAGE_MPH = 50;
const AVERAGE_KMPH = 80;

export const getPerMileComponent = (
  metadata?: RateMetadata
): MileageRate | undefined => {
  if (!metadata?.mileageRate) return undefined;
  return metadata.mileageRate;
};

export const getRateTotalDistanceDisplay = (
  carrierRate?: CarrierRate
): string | undefined => {
  const mileage = carrierRate?.metadata?.mileage;

  if (!mileage) return undefined;

  return `${formatNumber(mileage?.value, 5)} ${mileage?.units.toLowerCase()}`;
};

export const getRouteDuration = (
  carrierRate?: CarrierRate
): number | undefined => {
  const mileage = carrierRate?.metadata?.mileage;

  if (!mileage) return undefined;

  if (mileage.units === 'MI') {
    return Math.round((10 * mileage.value) / AVERAGE_MPH) / 10;
  } else if (mileage.units === 'KM') {
    return Math.round((10 * mileage.value) / AVERAGE_KMPH) / 10;
  }
};

export const isManualAdjustment = (
  rateComponent: RateComponent | MoneyFormRateLineItem
) => {
  return rateComponent.type === RateComponentType.MANUAL_ADJUSTMENT;
};

export function rateToLabel(rate: CarrierRate) {
  const ratingMethod = rate.ratingMethod;
  switch (ratingMethod) {
    case RatingMethod.CUSTOM:
      return 'Internal';
    case RatingMethod.DAT: {
      return rate?.metadata?.label || 'DAT';
    }
    case RatingMethod.GREENSCREENS: {
      return rate.metadata?.label || 'Greenscreens';
    }
    case RatingMethod.WERNER: {
      return rate?.metadata?.label || 'Werner';
    }
    case RatingMethod.HUMAN_INPUT:
      return 'Manual';
    case RatingMethod.SUNSET:
      return 'Sunset';
    case RatingMethod.NFI:
      return 'NFI';
    case RatingMethod.TABI:
      return 'Tabi';
    case RatingMethod.ARCBEST_TL_QUOTING:
      return 'ArcBest';
    case RatingMethod.ECHO:
      return 'Echo';
    case RatingMethod.TRANSFIX:
    case RatingMethod.BITFREIGHTER:
      return enumToString(ratingMethod);
    default:
      throw new Error(`Unknown rating method: ${ratingMethod}`);
  }
}

export function ratingMethodToLabel(ratingMethod: RatingMethod) {
  switch (ratingMethod) {
    case RatingMethod.CUSTOM:
      return 'Internal';
    case RatingMethod.DAT: {
      return 'DAT';
    }
    case RatingMethod.HUMAN_INPUT:
      return 'Manual';
    case RatingMethod.NFI:
      return 'NFI';
    case RatingMethod.ARCBEST_TL_QUOTING:
      return 'ArcBest';
    case RatingMethod.SUNSET:
    case RatingMethod.GREENSCREENS:
    case RatingMethod.TABI:
    case RatingMethod.WERNER:
    case RatingMethod.TRANSFIX:
    case RatingMethod.BITFREIGHTER:
    case RatingMethod.ECHO:
      return enumToString(ratingMethod);
    default:
      throw new Error(`Unknown rating method: ${ratingMethod}`);
  }
}

type HasRateFields = {
  createdAt: string;
  metadata?: RateMetadata;
  ratingMethod?: RatingMethod;
};

export type HasRatingMethod = {
  ratingMethod: RatingMethod;
};

type HasRateFieldsAndRatingMethod = HasRateFields & HasRatingMethod;

export function getRateMapKey<RateT extends HasRateFieldsAndRatingMethod>(
  rate: RateT
) {
  switch (rate.ratingMethod) {
    case RatingMethod.DAT: {
      const name = rate.metadata?.key || 'DAT';
      return `dat-${name}}`;
    }
    case RatingMethod.GREENSCREENS: {
      const name = rate.metadata?.key || 'Greenscreens';
      return `greenscreens-${name}`;
    }
    case RatingMethod.WERNER: {
      const name = rate.metadata?.key || 'Werner';
      return `werner-${name}`;
    }
    default:
      return rate.ratingMethod;
  }
}

export function filterRatesWithoutRatingMethod<
  InRateT extends HasRateFields,
  OutRateT extends HasRateFieldsAndRatingMethod,
>(rates: InRateT[]): OutRateT[] {
  return rates.filter((r) => !isNil(r.ratingMethod)) as unknown as OutRateT[];
}

export function selectNewestRatesOfEachMethod<RateT extends HasRateFields>(
  rates?: RateT[]
): (RateT & HasRatingMethod)[] {
  if (!rates || !rates.length) return [];
  const ratesWithMethod: (RateT & HasRatingMethod)[] =
    filterRatesWithoutRatingMethod(rates);
  const sorted = [...ratesWithMethod].sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });
  const map = new Map<string, RateT & HasRatingMethod>();
  sorted.forEach((r) => {
    const rateKey = getRateMapKey(r);
    if (!map.has(rateKey) && r.ratingMethod !== RatingMethod.HUMAN_INPUT) {
      map.set(rateKey, r);
    }
  });
  return Array.from(map.values()).sort((a, b) => {
    return getRateMapKey(a) < getRateMapKey(b) ? -1 : 1;
  });
}

export function sumCarrierRate(carrierRate?: CarrierRate) {
  if (!carrierRate?.computedRate) return 0;
  return sumRateComponents(
    filterRateComponents(carrierRate.computedRate.rateComponents)
  );
}

export function sumRateComponents(rate: RateComponent[]) {
  return rate.reduce((acc, curr) => acc + curr.value, 0);
}

export function sumFilteredRateComponents(rate: RateComponent[]) {
  return sumRateComponents(filterRateComponents(rate));
}

export function computeMarginPercent(buyRate: number, sellRate?: number) {
  if (!buyRate || !sellRate) return undefined;
  return ((sellRate - buyRate) / sellRate) * 100;
}

export function computeMarginDollars(buyRate: number, sellRate?: number) {
  if (!buyRate && sellRate) return sellRate;

  if (!buyRate || !sellRate) return undefined;
  return sellRate - buyRate;
}

export const toRateComponents = (
  rateLineItems: MoneyFormRateLineItem[],
  lineItemType: LineItemType
): RateComponent[] => {
  return rateLineItems.map((li, idx) => {
    const value = lineItemType === 'buy' ? li.buyRate : li.sellRate;
    return {
      description: li.description,
      type: li.type,
      componentId: li.componentId,
      value: parseFloat(value || '0'),
      index: idx,
    } as RateComponent;
  });
};

export function adjustCarrierRateComponents(
  carrierRate: CarrierRate,
  lineItems: MoneyFormRateLineItem[]
): Rate | null {
  return {
    ...carrierRate.computedRate,
    rateComponents: toRateComponents(lineItems, 'buy'),
  };
}

export function adjustCustomerRateComponents(
  customerRate: CustomerRate,
  lineItems: MoneyFormRateLineItem[]
): Rate | null {
  return {
    ...customerRate.rate,
    rateComponents: toRateComponents(lineItems, 'sell'),
  };
}

function computeManualAdjustmentForLineItemType({
  targetTotal,
  lineItems,
  lineItemType,
}: {
  targetTotal: number;
  lineItems: MoneyFormRateLineItem[];
  lineItemType: LineItemType;
}): string | null {
  const rateComponents = toRateComponents(lineItems, lineItemType);
  const currentTotal = sumFilteredRateComponents(rateComponents);
  const difference = targetTotal - currentTotal;

  if (difference === 0) return null;

  const adjustmentComponent = rateComponents.find(isManualAdjustment);
  const currentAdjustment = (adjustmentComponent as any)?.value || 0;
  return (Math.round((difference + currentAdjustment) * 100) / 100).toFixed(2);
}

export function manuallyAdjustBuyAndSell({
  buyRate,
  sellRate,
  lineItems,
}: {
  buyRate: number;
  sellRate: number;
  lineItems: MoneyFormRateLineItem[];
}): MoneyFormRateLineItem[] {
  const newBuyAdjustmentAmount = computeManualAdjustmentForLineItemType({
    targetTotal: buyRate,
    lineItems,
    lineItemType: 'buy',
  });
  const newSellAdjustmentAmount = computeManualAdjustmentForLineItemType({
    targetTotal: sellRate,
    lineItems,
    lineItemType: 'sell',
  });

  if (isNil(newBuyAdjustmentAmount) && isNil(newSellAdjustmentAmount))
    return lineItems;

  const adjustLineItem = lineItems.find(isManualAdjustment);

  return [
    ...lineItems.filter((rc) => !isManualAdjustment(rc)),
    {
      componentId: adjustLineItem?.componentId || v4(),
      buyRate: newBuyAdjustmentAmount || adjustLineItem?.buyRate || '0.00',
      sellRate: newSellAdjustmentAmount || adjustLineItem?.sellRate || '0.00',
      type: RateComponentType.MANUAL_ADJUSTMENT,
      description: MANUAL_ADJUSTMENT_RATE_COMPONENT_DESCRIPTION,
      index: adjustLineItem?.index || lineItems.length,
    },
  ].sort((a, b) => a.index - b.index);
}

export function manuallyAdjustSingleType(
  newRate: number,
  lineItems: MoneyFormRateLineItem[],
  lineItemType: LineItemType
): MoneyFormRateLineItem[] {
  const newAdjustmentAmount = computeManualAdjustmentForLineItemType({
    targetTotal: newRate,
    lineItems,
    lineItemType,
  });

  if (isNil(newAdjustmentAmount)) return lineItems;

  const adjustLineItem = lineItems.find(isManualAdjustment);

  return [
    ...lineItems.filter((rc) => !isManualAdjustment(rc)),
    {
      componentId: adjustLineItem?.componentId || v4(),
      buyRate:
        lineItemType === 'buy'
          ? newAdjustmentAmount
          : adjustLineItem?.buyRate || '0.00',
      sellRate:
        lineItemType === 'sell'
          ? newAdjustmentAmount
          : adjustLineItem?.sellRate || '0.00',
      type: RateComponentType.MANUAL_ADJUSTMENT,
      description: MANUAL_ADJUSTMENT_RATE_COMPONENT_DESCRIPTION,
      index: adjustLineItem?.index || lineItems.length,
    },
  ].sort((a, b) => a.index - b.index);
}

export const toMoneyFormValues = (initialValues: {
  buyRate: string;
  marginPercent?: string;
  marginDollars?: string;
  sellRate?: string;
  lineItems: RateLineItem[];
}): MoneyFormValues => {
  return {
    buyRate: initialValues.buyRate,
    marginPercent: initialValues.marginPercent || '',
    marginDollars: initialValues.marginDollars || '',
    sellRate: initialValues.sellRate || '',
    lineItems: initialValues.lineItems.map((lineItem, idx) => ({
      ...lineItem,
      buyRate: lineItem.buyRate.toFixed(2),
      sellRate: lineItem.sellRate.toFixed(2),
      description: lineItem.description || '',
      index: lineItem.index || idx,
    })),
  };
};
