import { createContext, useContext } from 'react';

enum Logos {
  GREENSCREENS = 'GREENSCREENS',
  DAT = 'DAT',
  SUNSET = 'SUNSET',
  TABI = 'TABI',
  WERNER = 'WERNER',
  ARCBEST = 'ARCBEST',
  ECHO = 'ECHO',
  TRANSFIX = 'TRANSFIX',
  BITFREIGHTER = 'BITFREIGHTER',
}
export type LogosURLsContextType = {
  logos: Record<Logos, string>;
};

export const LogosURLsContext = createContext<LogosURLsContextType>(
  buildEmptyContext()
);

export enum ApiReqType {
  PLACES = 'PLACES',
}

export function useLogosContext(): LogosURLsContextType['logos'] {
  const context = useContext(LogosURLsContext);

  if (!context) {
    throw new Error('APIContext must be used within a APIContextProvider');
  }
  return context.logos;
}

function buildEmptyContext(): LogosURLsContextType {
  return {
    logos: {
      GREENSCREENS: '',
      DAT: '',
      SUNSET: '',
      TABI: '',
      WERNER: '',
      ARCBEST: '',
      ECHO: '',
      TRANSFIX: '',
      BITFREIGHTER: '',
    },
  };
}
