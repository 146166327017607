import {
  LaneFilter,
  ShipmentHistorySummaryFragment,
  useGetLaneShipmentsQuery,
} from '@shared/generated/graphql';
import { isNil } from 'lodash';

type ShipmentFilters = {
  equipmentTypes?: string[];
  laneFilter?: LaneFilter;
};

export const useGetLaneShipments = (shipmentFilters?: ShipmentFilters) => {
  const { data, loading, error } = useGetLaneShipmentsQuery({
    variables: {
      input: {
        equipmentTypes: shipmentFilters?.equipmentTypes,
        laneFilter: shipmentFilters?.laneFilter,
      },
    },
  });

  const shipments =
    data?.shipments.edges.map((edge) => edge.node) ??
    ([] as ShipmentHistorySummaryFragment[]);

  // NOTE(max): If no lane filter is specified, we would show all shipments
  // which is not useful to the user (those shipments wouldn't be relevant to
  // the quote at hand) so we show none instead.
  const shouldShowShipments = !isNil(shipmentFilters?.laneFilter);
  const shownShipments = shouldShowShipments ? shipments : [];

  return {
    shipments: shownShipments,
    loading,
    error,
  };
};
